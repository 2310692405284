






































import './styles/FileCard.scss';
import './styles/FileCardAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FullNameFormatter } from '@utility/FullNameFormatter';
import { IFile } from '@store/modules/project-documents/Interfaces';
import { IConstructDocument } from '@store/modules/work-list/interfaces/Interfaces';

@Component({
    name: 'FileCard',
})
export default class FileCard extends Vue {
    @Prop({}) document!: IConstructDocument;
    @Prop({}) file!: IFile;

    get changerShortName() {
        if (this.file.changer && this.file.changer.name) {
            const fullNameFormatter = new FullNameFormatter(this.file.changer.name);
            return fullNameFormatter.getShortName();
        }
        return null;
    }

    get authorShortName() {
        const fullNameFormatter = new FullNameFormatter(this.document.author.fullName);
        return fullNameFormatter.getShortName();
    }

    get dateTitle() {
        return this.file.changeDate ? 'Дата изменения' : 'Дата';
    }

    get dateForCard() {
        const date = this.file.changeDate || this.document.createDate;
        return this.formattedDocumentCreateDate(date);
    }

    formattedDocumentCreateDate(date) {
        return new Intl.DateTimeFormat([], {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        })
        .format(new Date(date));
    }
}
