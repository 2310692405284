export class FullNameFormatter {
    fullName: string;

    constructor(fullName: string) {
        this.fullName = fullName || '';
    }

    getShortName(): string {
        const decompoundedName = this.fullName.split(' ');
        let shortName = `${decompoundedName[0] ?? ''}`;
        if (decompoundedName[1]) {
            shortName += `. ${decompoundedName[1][0] ?? ''}`;
        }
        if (decompoundedName[2]) {
            shortName += `. ${decompoundedName[2][0] ?? ''}`;
        }

        return shortName;
    }
}
